import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import BlogPosts from '../components/blog-post';
import { Container, Row, Col } from 'reactstrap';
import Img from 'gatsby-image';

const News = ({ data }) => {
    data.wpgraphql.page.acf_news.newsItem = data.wpgraphql.page.acf_news.newsItem.sort((a, b) => {
        const [m1, d1, y1] = a.date.split(".");
        const [m2, d2, y2] = b.date.split(".")

        const date1 = new Date(`${y1}-${m1}-${d1}`)
        const date2 = new Date(`${y2}-${m2}-${d2}`)

        return date2.getTime() - date1.getTime();
    });

    return (
        <Layout>
            <SEO
                title={data.wpgraphql.page.title}
                description={data.wpgraphql.page.acf_page_meta.metadescription}
            />
            <section className="section__news page type-page status-publish entry">
                <Container>
                    <header className="news__header ">
                        <Row>
                            <Col xs={{ offset: 1 }} sm={{ size: 10 }} lg={{ offset: 2 }}>
                                <h1 className="heading1">
                                    Ideal <strong>In The News.</strong>
                                </h1>
                            </Col>
                        </Row>
                    </header>
                </Container>

                {data.wpgraphql.page.acf_news.newsItem.map((items, i) => (
                    <article key={i}>
                        <Container>
                            <div className="news__post">
                                <Row>
                                    <Col
                                        xs={{ size: 12, offset: 1 }}
                                        sm={{ size: 10, offset: 2 }}
                                        md={{ size: 6, offset: 1, order: 1 }}
                                        lg={{ size: 6, offset: 2, order: 1 }}
                                    >
                                        <span className="news__post-date">{items.date}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        md={{ size: 4, offset: 1, order: 2 }}
                                        lg={{ size: 3, offset: 1, order: 2 }}
                                    >
                                        {items.image != null && (
                                            <Img
                                                className="w-100 d-none d-md-block"
                                                fixed={items.image.imageFile.childImageSharp.fixed}
                                            />
                                        )}
                                    </Col>
                                    <Col
                                        xs={{ size: 12, offset: 1 }}
                                        sm={{ size: 10, offset: 2 }}
                                        md={{ size: 6, offset: 1, order: 1 }}
                                        lg={{ size: 6, offset: 2, order: 1 }}
                                    >
                                        <h4 className="news__post-title heading4">
                                            <a
                                                href={items.linkUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {items.headline}
                                            </a>
                                        </h4>
                                        <p className="body-copy d-none d-md-block">
                                            {items.excerpt}
                                        </p>
                                        {items.image != null && (
                                            <Img
                                                className="w-100 d-md-none"
                                                fixed={items.image.imageFile.childImageSharp.fixed}
                                            />
                                        )}
                                        <a
                                            className="news__read-more"
                                            href={items.linkUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {items.linkText}
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </article>
                ))}
            </section>
        </Layout>
    );
};

export default News;

export const query = graphql`
    query {
        wpgraphql {
            page(id: "cGFnZTo5OQ==") {
                title
                acf_page_meta {
                    metadescription
                }
                acf_news {
                    newsItem {
                        date
                        headline
                        excerpt
                        linkUrl
                        linkText
                        image {
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fixed(width: 396, height: 256) {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
